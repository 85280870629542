<template>
    <v-layout fill-height align-center justify-center>
        <v-form ref="form">
            <v-card class="elevation-0"
                    width="600"
                    style="padding: 20px; border-radius: 16px; border: 1px solid #eee">
                <v-card-title class="display-1 font-weight-black" style="word-break: break-word;">
                    Новый пароль
                </v-card-title>
                <v-card-text style="margin-top: 20px;">
                    <v-layout justify-start>
                        <label class="subtitle-1 field-label">Почта</label>
                        <label class="subtitle-1">{{data.login}}</label>
                    </v-layout>

                    <v-layout style="margin-top: 10px;" align-center>
                        <label class="subtitle-1 field-label">Новый пароль</label>
                        <v-text-field
                                v-model="password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="rules.passwordRules"
                                validate-on-blur
                                color="secondary"
                                style="max-width: 300px;"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                @click:append="show1 = !show1"
                        />
                    </v-layout>

                    <v-layout style="margin-top: -5px;" align-center>
                        <label class="subtitle-1 field-label">Повторите пароль</label>
                        <v-text-field
                                v-model="repeatPassword"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="rules.repeatPasswordRules"
                                validate-on-blur
                                color="secondary"
                                @input="validatePassword"
                                style="max-width: 300px;"
                                :type="show2 ? 'text' : 'password'"
                                name="input-10-1"
                                @click:append="show2 = !show2"
                        />
                    </v-layout>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn :loading="loading"
                           @click="saveData"
                           color="secondary"
                           style="width: 190px; height: 45px; border-radius: 8px;">Сохранить
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-layout>
</template>

<script>
    import ValidationMixin from "../mixins/ValidationMixin";

    var shajs = require('sha.js');

    export default {
        name: "RecoveryView",
        mixins: [ValidationMixin],
        data: () => ({
            password: '',
            repeatPassword: '',
            show1: false,
            show2: false,
            repeatError: false,
            isDisabledError: false,
            loading: false,
            data: {}
        }),
        computed: {
            isMobile() {
                return this.$store.state.isMobile
            }
        },
        methods: {
            saveData() {
                this.validatePassword();
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    const params = {headers: {token: this.$route.query.token}};
                    let postData = {
                        passwordHash: shajs('sha256').update(this.password).digest('hex')
                    };
                    this.$http.post("/profile/recovery/password", postData, params)
                        .then(response => {
                            localStorage.setItem('token', response.headers.token);
                            this.$router.history.push({name: 'users'})
                        }).catch(error => {
                        console.log(error);
                        switch (error.response.status) {
                            case 406:
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            },
            validatePassword() {
                if (this.password !== this.repeatPassword) {
                    this.repeatError = true;
                    this.$nextTick().then(() => {
                        this.$refs.form.validate()
                    })
                } else this.repeatError = false
            },
            exit() {
                this.$router.history.replace({name: 'login'})
            }
        },
        beforeMount() {
            const token = this.$route.query.token;
            if (token) {
                const params = {
                    headers: {token}
                };
                this.$http.get('/profile/recovery/check', params)
                    .then(response => {
                        console.log('here')
                        this.data = response.data.data
                    }).catch(error => {
                    console.log(error)
                    this.exit()
                })
            } else this.exit()
        }
    }
</script>

<style scoped>
    .field-label {
        width: 180px;
    }
</style>